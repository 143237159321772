Mosaic.addPlugins([require('/var/www/html/app/design/frontend/PlugAndSell2/pwa/packages/contractors-register/src/plugin/MyAccountCreateAccount.plugin.tsx')]);
import Consents, { CONSENTS_NEWSLETTER, CONSENTS_REGISTER } from 'consents';
import { ChangeEvent, PureComponent } from 'react';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';
import { validatePassword } from 'Util/Validator';
import { ValidationInputType } from 'Util/Validator/Config';

import { MyAccountCreateAccountComponentProps, MyAccountCreateAccountComponentState } from './MyAccountCreateAccount.type';

import './MyAccountCreateAccount.style.scss';

/** @namespace PlugAndSell2/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends PureComponent<MyAccountCreateAccountComponentProps, MyAccountCreateAccountComponentState> {
    state = {
        isNewsletterChecked: false,
    };

    handleNewsletterOnChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ isNewsletterChecked: event?.target.checked });
    }

    renderCompanyField(): ReactElement {
        return (
            <Field
                type={FieldType.TEXT}
                label={__('Company')}
                attr={{
                    id: 'company',
                    name: 'company',
                    placeholder: __('Company'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                }}
                addRequiredTag
            />
        );
    }

    renderEmail(): ReactElement {
        const {
            location: { state: { email = '' } = {} },
        } = history;

        return (
            <Field
                type={FieldType.EMAIL}
                label={__('Email')}
                attr={{
                    id: 'email',
                    name: 'email',
                    defaultValue: email,
                    placeholder: __('Email address'),
                    autoComplete: 'email',
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: true,
                    inputType: ValidationInputType.EMAIL,
                }}
                addRequiredTag
            />
        );
    }

    renderVatNumberField(): ReactElement {
        const { isB2B, showTaxVatNumber, vatNumberRequired } = this.props;

        if (!showTaxVatNumber || !isB2B) {
            return null;
        }

        return (
            <Field
                type={FieldType.TEXT}
                label={__('Tax/VAT Number')}
                attr={{
                    id: 'taxvat',
                    name: 'taxvat',
                    placeholder: __('Your Tax/VAT Number'),
                }}
                validateOn={['onChange']}
                validationRule={{
                    isRequired: vatNumberRequired,
                }}
                addRequiredTag={vatNumberRequired}
            />
        );
    }

    renderSubscribeToNewsletter(): ReactElement {
        return (
            <Field
                type={FieldType.CHECKBOX}
                label={__('Subscribe to newsletter')}
                attr={{
                    id: 'is_subscribed',
                    name: 'is_subscribed',
                    placeholder: __('Your Tax/VAT Number'),
                }}
                events={{
                    onChange: this.handleNewsletterOnChange.bind(this),
                }}
                mix={{ block: 'MyAccountOverlay', elem: 'NewsletterCheckbox' }}
            />
        );
    }

    renderCreateAccountPersonalInfoFields(): ReactElement {
        const { isB2B, newsletterActive } = this.props;
        const {
            location: { state: { firstName = '', lastName = '' } = {} },
        } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend" mods={{ personalInfo: true }}>
                {isB2B && this.renderEmail()}
                <Field
                    type={FieldType.TEXT}
                    label={__('Name')}
                    attr={{
                        id: 'firstname',
                        name: 'firstname',
                        defaultValue: firstName,
                        placeholder: __('Name'),
                        autoComplete: 'given-name',
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                    }}
                    addRequiredTag
                    withErrorMessage
                />
                <Field
                    type={FieldType.TEXT}
                    label={__('Last Name')}
                    attr={{
                        id: 'lastname',
                        name: 'lastname',
                        defaultValue: lastName,
                        placeholder: __('Last name'),
                        autoComplete: 'family-name',
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                    }}
                    addRequiredTag
                    withErrorMessage
                />
                {this.renderVatNumberField()}
                {this.renderCompanyField()}
                {newsletterActive && !isB2B ? this.renderSubscribeToNewsletter() : null}
            </fieldset>
        );
    }

    renderCreateAccountSignUpInfoFields(): ReactElement {
        const { isB2B, range, minimunPasswordCharacter, displayedAsPage } = this.props;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend" mods={{ signUpInfo: true }}>
                {!isB2B && this.renderEmail()}
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    <Field
                        type={FieldType.PASSWORD}
                        label={__('Password')}
                        attr={{
                            id: displayedAsPage ? 'password-page' : 'password-popup',
                            name: 'password',
                            placeholder: __('Password'),
                            autoComplete: 'new-password',
                        }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                            inputType: ValidationInputType.PASSWORD,
                            match: (value: string) => {
                                const email = document.getElementById('email') as HTMLInputElement;

                                if (value && email.value === value) {
                                    return __("Passwords can't be the same as email!");
                                }

                                const passwordConfirmation = document.getElementById(
                                    displayedAsPage ? 'confirm-password-page' : 'confirm-password-popup'
                                ) as HTMLInputElement;

                                if (passwordConfirmation.value) {
                                    passwordConfirmation.dispatchEvent(new Event('validate', { bubbles: true }));
                                }

                                return validatePassword(value, range, minimunPasswordCharacter);
                            },
                        }}
                        addRequiredTag
                        withErrorMessage
                    />
                    <Field
                        type={FieldType.PASSWORD}
                        label={__('Confirm password')}
                        attr={{
                            id: displayedAsPage ? 'confirm-password-page' : 'confirm-password-popup',
                            name: 'confirm_password',
                            placeholder: __('Confirm password'),
                            autoComplete: 'new-password',
                        }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                            inputType: ValidationInputType.PASSWORD,
                            match: (value: string) => {
                                const password = document.getElementById(displayedAsPage ? 'password-page' : 'password-popup') as HTMLInputElement;

                                return value && password.value === value;
                            },
                            customErrorMessages: {
                                onMatchFail: __('Passwords do not match!'),
                            },
                        }}
                        addRequiredTag
                        withErrorMessage
                    />
                </div>
            </fieldset>
        );
    }

    renderRegisterConsents(): ReactElement {
        return <Consents type={CONSENTS_REGISTER} />;
    }

    renderNewsletterConsents(): ReactElement {
        const { isNewsletterChecked } = this.state;

        if (!isNewsletterChecked) {
            return;
        }

        return <Consents type={CONSENTS_NEWSLETTER} />;
    }

    renderSubmitButton(): ReactElement {
        const { displayedAsPage } = this.props;

        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <button block="Button" type="submit" mods={{ size: !displayedAsPage && 'medium', variant: 'secondary' }}>
                    {__('Sign up')}
                </button>
            </div>
        );
    }

    renderCreateAccountForm(): ReactElement {
        const { onError, onSuccess } = this.props;

        return (
            <Form key="create-account" onSubmit={onSuccess} onError={onError}>
                {this.renderCreateAccountPersonalInfoFields()}
                {this.renderCreateAccountSignUpInfoFields()}
                {this.renderNewsletterConsents()}
                {this.renderRegisterConsents()}
                {this.renderSubmitButton()}
            </Form>
        );
    }

    renderAdditionalField(): ReactElement {
        const { handleSignIn } = this.props;

        return (
            <article block="MyAccountOverlay" elem="Additional">
                <section>
                    <span block="MyAccountOverlay" elem="AdditionalLabel">
                        {__('Already have an account?')}
                    </span>
                    <button block="Button" mods={{ likeLink: true }} mix={{ block: 'MyAccountOverlay', elem: 'SignInLink' }} onClick={handleSignIn}>
                        {__('Sign In')}
                    </button>
                </section>
            </article>
        );
    }

    render(): ReactElement {
        return (
            <div block="MyAccountCreateAccount">
                {this.renderCreateAccountForm()}
                {this.renderAdditionalField()}
            </div>
        );
    }
}

export default MyAccountCreateAccountComponent;
